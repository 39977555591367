@tailwind base;
@tailwind components;
@tailwind utilities;

.contents {
  margin-left: 50px;
  margin-right: 50px;
  /*border: solid 1px;*/
  padding-left: 55px;
  margin-bottom: 100px; 
}

@media screen and (max-width: 44em) {
  .contents {
    margin-left: 5%;
    margin-right: 5%;
    /*border: solid 1px;*/
    padding-left: 0px;
    margin-bottom: 0px; 
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
