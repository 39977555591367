.social {
  a {
    text-decoration: none;
  }

  a:visited {
    color: blue;
  }

  width: 150px;
}

/* Style all font awesome icons */
.fa {
  padding: 5px;
  width: 19px;
  height: 19px;
  vertical-align: middle;
  text-align: center;
  border-radius: 20%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-telegram {
  background: white;
  color: #55ACEE;
}

.fa-instagram {
  background: rgb(240, 68, 68);
  color: white;
}

.fa-vk {
  background: rgb(18, 14, 216);
  color: white;
}

.commentary {
  padding-left: 20px;
}
