.gridDisplay {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 64em) {
    .gridDisplay {
        display: block;
    }
}

.tripCard {
    margin: 5px;
    padding: 2px;
    display: block;

    a {
        text-decoration: none;
    }
}

.tripImage {
    max-width: 200px;
    max-height: 140px;
}

.tripName {
    color: #242221;
    font-weight: 500;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    text-decoration: none;
    position: relative;
}

.tripCard:hover .tripName {
    color: red
}