.menu {
  margin: 25px;
  background: #f9f9f9;
  padding: 16px;

  .menuItems {
    list-style: none;
    display: flex;

    li {
      width: 20%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 64em) {
  .menu {
    margin: 0px;
    background: #f9f9f9;
    padding: 0px;
  
    .menuItems {
      list-style: none;
      display: block;
  
      li {
        float: left;
        background: #f9f9f9;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.menuLink {
  position: relative;
  text-decoration: none;
  color: #8f8f8f;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  transition: 0.2s ease color;
}

.menuLink:before,
.menuLink:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.2s ease transform;
}

.menuLink:hover {
  color: red;
}


.footer {
  position: fixed;
  bottom: 0px;
  font-size: 12px;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  background-color: #f1f1f1;
  color: #000000;
  text-align: center;

  a {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid;

    &:hover {
      border-bottom: 1px transparent;
    }
  }
}

.searchBox {
  display: flex;
  justify-content: flex-end;
  margin: 25px;
  padding: 1px;
}

.searchButton {
  margin-left: 2px;
  background-color: white;
  border: none;
}